import { useEffect, useState } from 'react';
import { Plan } from '@pickme/core';

import PlanLayout from 'components/common/layout/Plan';
import PlanCards from 'components/features/plan/Cards';
import PlanReplaceModal from 'components/features/plan/modal/PlanReplace';

import { useGetOrganization } from 'query-hooks/organization';

import { useNavigateWithOrganizationId } from 'hooks/useNavigateWithOrganizationId';

import { getOrganizationIdFromQueryString } from 'functions/url';

function PlanReplacement() {
  const navigate = useNavigateWithOrganizationId();

  const [planReplaceModal, setPlanReplaceModal] = useState({
    isVisible: false,
    selectedPlan: Plan.Free,
  });

  const organizationId = getOrganizationIdFromQueryString();
  const { data: organizationData } = useGetOrganization(organizationId);
  const plan = organizationData?.subscription?.type;

  useEffect(() => {
    if (plan && [Plan.Free].includes(plan)) {
      navigate(`/plan/guide?type=monthly`, { replace: true });
    }

    if (plan && [Plan.Enterprise, Plan.Campus].includes(plan)) {
      navigate(`/`, { replace: true });
    }
  }, [plan]);

  return (
    <>
      <PlanLayout>
        {plan && (
          <PlanCards
            plan={plan}
            onPlanClick={(selectedPlan) => setPlanReplaceModal({ isVisible: true, selectedPlan })}
          />
        )}
      </PlanLayout>

      <PlanReplaceModal
        isVisible={planReplaceModal.isVisible}
        from={plan}
        to={planReplaceModal.selectedPlan}
        organizationId={organizationId}
        onClose={() =>
          setPlanReplaceModal({
            isVisible: false,
            selectedPlan: Plan.Free,
          })
        }
      />
    </>
  );
}

export default PlanReplacement;
