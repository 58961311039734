import { useNavigate, NavigateOptions } from 'react-router-dom';

import { getOrganizationIdFromQueryString } from 'functions/url';

export const useNavigateWithOrganizationId = () => {
  const navigate = useNavigate();

  return (to: string, options?: NavigateOptions) => {
    const baseUrl = import.meta.env.VITE_INDEX_URL;
    const url = new URL(to, baseUrl);

    if (!url.searchParams.get('organizationId')) {
      const organizationId = getOrganizationIdFromQueryString();
      url.searchParams.set('organizationId', organizationId);
    }

    const path = url.pathname + url.search;

    navigate(path, options);
  };
};
