import { useRecoilValue } from 'recoil';
import { Text, Modal, ModalBody, ModalHeader, ModalActions } from '@pickme/ui';
import { t } from 'i18next';

import { adminRedirectModal } from 'states/modal';

import { redirectToAdmin } from 'functions/url';

function AdminRedirectModal() {
  const { isVisible, title, message } = useRecoilValue(adminRedirectModal);

  const onClose = () => redirectToAdmin();

  return (
    <Modal isVisible={isVisible} onClose={onClose}>
      <ModalHeader title={title} onClose={onClose} />
      <ModalBody>
        <Text size='sm1' fontWeight={300} color='gray-400'>
          {message}
        </Text>
      </ModalBody>
      <ModalActions
        buttons={[
          {
            text: t('payment:components.modal.admin-redirect.button.submit'),
            onClick: onClose,
          },
        ]}
      />
    </Modal>
  );
}

export default AdminRedirectModal;
