import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSetRecoilState } from 'recoil';
import { Plan } from '@pickme/core';
import { t } from 'i18next';

import { createPurchaseSubscriptionRequest, createReplacePlanRequest } from 'apis/payment';

import { useNavigateWithOrganizationId } from 'hooks/useNavigateWithOrganizationId';

import { adminRedirectModal, messageModal } from 'states/modal';

import { calculatePrice, parseSubscriptionSuccessMessage } from 'functions/payment';
import { sendReplacePlanEvent, sendSubscribeEvent } from 'functions/gtag';

import { SubscriptionOrderForm } from 'types/payment';

export const useReplacePlan = () => {
  const queryClient = useQueryClient();
  const setAdminRedirectModal = useSetRecoilState(adminRedirectModal);

  return useMutation(
    async ({ plan, organizationId }: { plan: Plan; organizationId: string }) => {
      const { data } = await createReplacePlanRequest(plan, organizationId);

      return data;
    },
    {
      onSuccess: (_, { plan }) => {
        queryClient.refetchQueries({ queryKey: ['auth'] });
        queryClient.refetchQueries({ queryKey: ['payment-records'] });
        queryClient.refetchQueries({ queryKey: ['payment'] });
        queryClient.refetchQueries({ queryKey: ['organization'] });

        setAdminRedirectModal({
          isVisible: true,
          title: t('payment:query-hooks.payment.replacePlan.success.modal.title'),
          message:
            plan === Plan.Free
              ? t('payment:query-hooks.payment.replacePlan.success.modal.message.abort')
              : t('payment:query-hooks.payment.replacePlan.success.modal.message.replace'),
        });

        sendReplacePlanEvent({
          plan,
        });
      },
    },
  );
};

export const useCreatePurchaseSubscription = () => {
  const navigate = useNavigateWithOrganizationId();
  const queryClient = useQueryClient();
  const setMessageModal = useSetRecoilState(messageModal);
  const setAdminRedirectModal = useSetRecoilState(adminRedirectModal);

  return useMutation(
    async ({
      orderForm,
      authKey,
      organizationId,
    }: {
      orderForm: SubscriptionOrderForm;
      authKey: string;
      organizationId: string;
    }) => {
      const { data } = await createPurchaseSubscriptionRequest(orderForm, authKey, organizationId);
      return data;
    },
    {
      onSuccess: (_, { orderForm }) => {
        queryClient.resetQueries(['auth']);
        queryClient.resetQueries(['organization']);
        queryClient.resetQueries(['organizations']);

        setAdminRedirectModal({
          isVisible: true,
          title: t('payment:query-hooks.payment.subscription.success.modal.title'),
          message: parseSubscriptionSuccessMessage(orderForm.planType, orderForm.contractType),
        });

        sendSubscribeEvent({
          plan: orderForm.planType,
          voterLimit: orderForm.voterLimit,
          price: calculatePrice(orderForm.planType, orderForm.voterLimit),
        });
      },
      onError: (error: any) => {
        navigate(`/guide?type=monthly`, { replace: true });

        setMessageModal({
          isVisible: true,
          title: t('payment:error.paymentFailure.title'),
          // TODO: Fix error message;
          message: t('payment:error.paymentFailure.message', {
            text: error.message,
          }),
        });
      },
    },
  );
};
