import { Plan } from '@pickme/core';

import { createHttpRequest } from 'apis/common';

import { SubscriptionOrderForm } from 'types/payment';
import { Apis } from 'types/api';

const APIS: Apis = {
  replacePlan: { method: 'PUT', url: '/api/subscription/plan/reservation' },
  createSubscriptionPoint: { method: 'POST', url: '/api/admin/card' },
};

export const createReplacePlanRequest = (plan: Plan, organizationId: string) =>
  createHttpRequest({
    ...APIS.replacePlan,
    headers: {
      'organization-oid': organizationId,
    },
    data: { planType: plan },
  });

export const createPurchaseSubscriptionRequest = (
  orderForm: SubscriptionOrderForm,
  authKey: string,
  organizationId: string,
) =>
  createHttpRequest<{ message: 'ok' }>({
    ...APIS.createSubscriptionPoint,
    headers: {
      'organization-oid': organizationId,
    },
    data: {
      authKey,
      orderForm,
    },
  });
