import { FormProvider, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';
import { Text, Button, Radio, Input } from '@pickme/ui';
import { PLANS } from '@pickme/core';
import { loadTossPayments } from '@tosspayments/payment-sdk';
import moment from 'moment-timezone';
import { t } from 'i18next';

import { messageModal } from 'states/modal';
import { toast } from 'states/toast';

import { calculatePrice } from 'functions/payment';

import { BillFormValues, PlanForPurchase } from 'types/payment';

import { VOTER_LIMIT } from 'constants/payment';

type Props = {
  organizationId: string;
  organizationName: string;
  customerKey: string;
  planType: PlanForPurchase;
  voterLimit: number;
};

function BillForm({ organizationId, organizationName, customerKey, planType, voterLimit }: Props) {
  const setToast = useSetRecoilState(toast);
  const setMessageModal = useSetRecoilState(messageModal);

  const methods = useForm<BillFormValues>({
    defaultValues: {
      planType,
      voterLimit,
    },
    mode: 'onChange',
  });

  const watchedVoterLimit = methods.watch('voterLimit');
  const localePrice = calculatePrice(planType, watchedVoterLimit).toLocaleString('ko-KR');

  const today = moment().tz('Asia/Seoul');
  const nextPayday = moment().tz('Asia/Seoul').add(1, 'month');

  const onSubmit: SubmitHandler<BillFormValues> = async (data) => {
    try {
      const clientKey = import.meta.env.VITE_TOSS_BILLING_CLIENT_KEY;

      const tossPayments = await loadTossPayments(clientKey);

      tossPayments.requestBillingAuth('카드', {
        customerKey,
        successUrl: `${
          import.meta.env.VITE_INDEX_URL
        }/callback/success?product=subscription&contractType=monthly&planType=${
          data.planType
        }&voterLimit=${data.voterLimit}&organizationId=${organizationId}`,
        failUrl: `${
          import.meta.env.VITE_INDEX_URL
        }/callback/failure?product=subscription&organizationId=${organizationId}`,
      });
    } catch (error: any) {
      setMessageModal({
        isVisible: true,
        title: t('payment:error.paymentFailure.title'),
        message: t('payment:error.paymentFailure.message', {
          text: error.message || t('payment:error.wrongRequest'),
        }),
      });
    }
  };

  const onError: SubmitErrorHandler<BillFormValues> = (error) => {
    setToast({
      isVisible: true,
      type: 'error',
      message: error.voterLimit?.message || '',
    });
  };

  return (
    <FormProvider {...methods}>
      <form className='payment-bill__form' onSubmit={methods.handleSubmit(onSubmit, onError)}>
        <div className='payment-bill__form__row'>
          <div className='payment-bill__form__row__title'>
            <Text type='b1' fontWeight={500} color='gray-600'>
              {t('payment:pages.bill.form.subscriptionPlan')}
            </Text>
          </div>

          <div className='payment-bill__form__row__content'>
            <Text type='b2' fontWeight={400} color='gray-400'>
              {PLANS[planType].name.kor}
            </Text>
          </div>
        </div>

        <div className='payment-bill__form__row'>
          <div className='payment-bill__form__row__title'>
            <Text type='b1' fontWeight={500} color='gray-600'>
              {t('payment:pages.bill.form.contract.label')}
            </Text>
          </div>

          <div className='payment-bill__form__row__content payment-bill__form__row__radio-fields'>
            <Radio checked>
              <Text type='b3' fontWeight={400}>
                {t('payment:pages.bill.form.contract.type.monthly')}
              </Text>
            </Radio>
          </div>
        </div>

        <div className='payment-bill__form__row'>
          <div className='payment-bill__form__row__title'>
            <Text type='b1' fontWeight={500} color='gray-600'>
              {t('payment:pages.bill.form.period.label')}
            </Text>
          </div>

          <div className='payment-bill__form__row__content'>
            <Text type='b2' fontWeight={400} color='gray-400'>
              {`${t('payment:pages.bill.form.period.format', {
                year: today.year(),
                month: today.month() + 1,
                date: today.date(),
              })} ~ ${t('payment:pages.bill.form.period.format', {
                year: nextPayday.year(),
                month: nextPayday.month() + 1,
                date: nextPayday.date(),
              })}`}
            </Text>
          </div>
        </div>

        <div className='payment-bill__form__row'>
          <div className='payment-bill__form__row__title'>
            <Text type='b1' fontWeight={500} color='gray-600'>
              {t('payment:pages.bill.form.nextPayday')}
            </Text>
          </div>

          <div className='payment-bill__form__row__content'>
            <Text type='b2' fontWeight={400} color='gray-400'>
              {t('payment:pages.bill.form.period.format', {
                year: nextPayday.year(),
                month: nextPayday.month() + 1,
                date: nextPayday.date(),
              })}
            </Text>
          </div>
        </div>

        <hr />

        <div className='payment-bill__form__row'>
          <div className='payment-bill__form__row__title'>
            <Text type='b1' fontWeight={500} color='gray-600'>
              {t('payment:pages.bill.form.organization')}
            </Text>
          </div>

          <div className='payment-bill__form__row__content'>
            <Text type='b2' fontWeight={400} color='gray-400'>
              {organizationName}
            </Text>
          </div>
        </div>

        <div className='payment-bill__form__row'>
          <div className='payment-bill__form__row__title'>
            <Text type='b1' fontWeight={500} color='gray-600'>
              {t('payment:pages.bill.form.voter.label')}
            </Text>
          </div>

          <div className='payment-bill__form__row__content'>
            <Input
              type='number'
              {...methods.register('voterLimit', {
                valueAsNumber: true,
                required: true,
                validate: (value) => {
                  if (value < VOTER_LIMIT.MIN) {
                    return t('payment:pages.bill.form.voter.validator.minVoterLimit');
                  }

                  if (value > VOTER_LIMIT.MAX) {
                    return t('payment:pages.bill.form.voter.validator.maxVoterLimit');
                  }

                  return true;
                },
              })}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={(event) => {
                const value = Number(event.target.value);
                methods.setValue('voterLimit', value);
              }}
              isValid={!methods.formState.errors.voterLimit}
            />
          </div>
        </div>

        <div className='payment-bill__form__row'>
          <div className='payment-bill__form__row__title'>
            <Text type='b1' fontWeight={500} color='gray-600'>
              {t('payment:pages.bill.form.amount.label')}
            </Text>

            <Text type='b2' fontWeight={500} color='gray-300'>
              ({t('payment:pages.bill.form.vat')})
            </Text>
          </div>

          <div className='payment-bill__form__row__content'>
            <Text type='b2' fontWeight={400} color='gray-400'>
              {localePrice}
              {t('payment:term.currency')} / {t('payment:pages.bill.form.amount.per.monthly')}
            </Text>
          </div>
        </div>

        <hr />

        <div className='payment-bill__form__row'>
          <div className='payment-bill__form__row__title'>
            <Text type='b1' fontWeight={500} color='gray-600'>
              {t('payment:pages.bill.form.sum')}
            </Text>

            <Text type='b2' fontWeight={500} color='gray-300'>
              ({t('payment:pages.bill.form.vat')})
            </Text>
          </div>

          <Text type='h4' fontWeight={600} color='gray-500'>
            {localePrice}
            {t('payment:term.currency')}
          </Text>
        </div>
        <div className='payment-bill__form__button'>
          <Button width='100%' size='md' type='submit'>
            {t('payment:pages.bill.form.button.pay')}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}

export default BillForm;
