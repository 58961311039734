import { VOTER_LIMIT } from 'constants/payment';

export default {
  title: '플랜 정보',
  cautions: {
    title: '유의 사항',
    voterLimit: '투표인 수는 개별 투표당 최대 등록 가능한 대상자 수를 의미합니다.',
    retry: '결제 예정일에 결제되지 않으면 이후 7일간 하루 3번 결제가 시도됩니다.',
  },

  form: {
    subscriptionPlan: '구독 플랜',
    contract: {
      label: '계약 주기',
      type: {
        monthly: '월간',
      },
    },
    period: {
      label: '구독 기간',
      format: '{{year}}년 {{month}}월 {{date}}일',
    },
    nextPayday: '다음 정기 결제일',
    organization: '스페이스',
    voter: {
      label: '투표인 수',
      validator: {
        minVoterLimit: `최소 투표인 수는 ${VOTER_LIMIT.MIN}명입니다.`,
        maxVoterLimit: `최대 투표인 수는 ${VOTER_LIMIT.MAX}명입니다.`,
      },
    },
    amount: {
      label: '결제 금액',
      per: {
        monthly: '월',
      },
    },
    sum: '합계',
    vat: '부가세 포함',
    button: {
      pay: '결제하기',
    },
  },
};
