import { PlainButton, Text } from '@pickme/ui';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';

import BackIcon from 'resources/icons/nav/back.png';

function Navbar({ title }: { title?: string }) {
  const navigate = useNavigate();

  return (
    <header className='payment-header'>
      <div className='payment-header__title-container'>
        <PlainButton onClick={() => navigate(-1)} className='payment-header__back-button'>
          <img className='payment-header__back-icon' src={BackIcon} alt='back' />
        </PlainButton>

        <Text size='md3' fontWeight={600}>
          {title ?? t('payment:layout.payment.navbar.default')}
        </Text>
      </div>
    </header>
  );
}

export default Navbar;
