import { Plan, PLANS } from '@pickme/core';
import { t } from 'i18next';

import { PlanForPurchase } from 'types/payment';

export const calculatePrice = (planType: PlanForPurchase, voterLimit: number) => {
  let planFactor = 1;
  if (planType === Plan.Basic) {
    planFactor = 300;
  }
  if (planType === Plan.Standard) {
    planFactor = 400;
  }
  if (planType === Plan.Premium) {
    planFactor = 600;
  }

  return planFactor * voterLimit * 1.1;
};

export const parseSubscriptionSuccessMessage = (
  planType: Plan,
  contractType: 'monthly' | 'yearly',
) => {
  const planText = `${PLANS[planType].name.kor} ${t(
    'payment:functions.payment.parseSubscriptionSuccessMessage.plan',
  )}`;

  let contractTypeText = '';
  if (contractType === 'monthly') {
    contractTypeText = t(
      'payment:functions.payment.parseSubscriptionSuccessMessage.contractType.monthly',
    );
  } else {
    contractTypeText = t(
      'payment:functions.payment.parseSubscriptionSuccessMessage.contractType.yearly',
    );
  }

  return t('payment:functions.payment.parseSubscriptionSuccessMessage.message', {
    planText,
    contractTypeText,
  });
};
