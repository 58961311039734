export const getOrganizationIdFromQueryString = () => {
  const { search } = window.location;
  const URLSearch = new URLSearchParams(search);
  const organizationId = URLSearch.get('organizationId') || '';

  return organizationId;
};

export const redirectToAdmin = () => {
  window.open(`${import.meta.env.VITE_ADMIN_URL}/settings/payment/subscription`, '_self');
};
