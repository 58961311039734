import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { RecoilRoot } from 'recoil';

import PaymentAuth from 'components/common/Auth';
import Toast from 'components/common/Toast';
import MessageModal from 'components/features/plan/modal/MessageModal';
import AdminRedirectModal from 'components/features/plan/modal/AdminRedirectModal';

import Navigator from './Navigator';

import 'styles/global.scss';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 2 * 60 * 1000,
      cacheTime: 2 * 60 * 1000,
      retry: (failureCount, error: any) => {
        // organization-oid가 필요하지만, header에 포함 하지 않은 경우.
        if (error?.code === 'NEED_ORGANIZATION_OID') {
          return failureCount <= 2;
        }

        return false;
      },
      retryDelay: 100,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <PaymentAuth>
          <Navigator />
          <Toast />
          <MessageModal />
          <AdminRedirectModal />
        </PaymentAuth>
      </RecoilRoot>
    </QueryClientProvider>
  );
}

export default App;
