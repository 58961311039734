export default {
  'admin-redirect': {
    button: {
      submit: '확인',
    },
  },
  message: {
    button: {
      submit: '확인',
    },
  },
  'plan-replace': {
    title: '플랜 변경',
    message: {
      planTo: '이용 중인 계약이 종료된 다음날부터 {{plan}} 플랜으로 변경하시겠어요?',
      desc: {
        prefix: '{{plan}}으로 변경시 생성 가능한',
        vote: '투표수가 {{number}}개',
        increase: '로 늘어납니다.',
        decrease: '로 줄어듭니다.',
      },
    },
    buttons: {
      cancel: '취소',
      submit: '변경하기',
    },
  },
};
