import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import { t } from 'i18next';

import PaymentLayout from 'components/common/layout/Payment';

import PlanGuide from 'pages/subscription/PlanGuide';
import Bill from 'pages/subscription/Bill';
import PlanReplacement from 'pages/subscription/PlanReplacement';

import PaymentSuccessCallback from 'pages/PaymentSuccessCallback';
import PaymentFailureCallback from 'pages/PaymentFailureCallback';
import PageNotFound from 'pages/redirect/PageNotFound';

import { useSendSentryReport, useSentryUser } from 'hooks/useSentry';

function Navigator() {
  useSendSentryReport();
  useSentryUser();

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route id='root'>
        <Route id='main-payment'>
          <Route id='main-payment-layout' element={<PaymentLayout />}>
            <Route id='main-payment-layout-guide' path='guide' element={<PlanGuide />} />
            <Route id='main-payment-layout-bill' path='bill' element={<Bill />} />
          </Route>

          <Route id='main-payment-callback-layout' element={<PaymentLayout />}>
            <Route id='main-payment-callback' path='callback'>
              <Route
                id='main-payment-callback-success'
                path='success'
                element={<PaymentSuccessCallback />}
              />
              <Route
                id='main-payment-callback-failure'
                path='failure'
                element={<PaymentFailureCallback />}
              />
            </Route>
          </Route>
        </Route>

        <Route
          id='main-plan'
          path='plan'
          element={<PaymentLayout title={t('payment:layout.payment.navbar.replace')} />}
        >
          <Route id='main-plan-replace' path='replace' element={<PlanReplacement />} />
        </Route>

        <Route path='*' element={<PageNotFound />} />
      </Route>,
    ),
  );

  return <RouterProvider router={router} />;
}

export default Navigator;
