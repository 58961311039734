import { useQuery, useQueryClient } from '@tanstack/react-query';
import { createGetSessionAsMemberRequest } from 'apis/auth';
import { setUser } from 'functions/gtag';

export const useGetSessionAsMember = (organizationId: string) => {
  const queryClient = useQueryClient();

  return useQuery(
    ['auth', 'session', 'member'],
    async () => {
      const { data } = await createGetSessionAsMemberRequest(organizationId);

      setUser({
        userId: data._id,
        spaceId: data.organizationId,
      });

      return data;
    },
    {
      refetchOnReconnect: !!queryClient.getQueryData(['auth', 'session', 'member']),
      refetchOnWindowFocus: !!queryClient.getQueryData(['auth', 'session', 'member']),
      useErrorBoundary: false,
      staleTime: 10 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
    },
  );
};
