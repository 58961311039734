import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { AxiosError } from 'axios';

import { ProfileAsMember } from 'types/auth';

export const initSentry = () => {
  if (process.env.NODE_ENV === 'development') {
    return;
  }

  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing(),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: true,
      }),
    ],
    environment: import.meta.env.NODE_ENV,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    normalizeDepth: 5,
  });
  Sentry.setTag('service', 'payment');
};

export const sendApiErrorReportToSentry = (error: AxiosError) => {
  Sentry.withScope((scope) => {
    scope.setFingerprint([
      error.config?.method || '',
      (error.response?.status || '').toString(),
      error.config?.url || '',
    ]);

    Sentry.setTag('level', 'api');
    Sentry.captureException(error);
  });
};

export const setSentryApiDetail = (error: AxiosError) => {
  const request: { [key: string]: any } = {
    method: error.config?.method,
    url: error.config?.url,
  };

  if (error.config?.headers) {
    request.headers = error.config?.headers;
  }

  if (error.config?.params) {
    request.params = error.config?.params;
  }

  if (error.config?.data) {
    request.data = error.config?.data;
  }

  Sentry.setContext('API Detail', {
    request,
    error: {
      status: error.response?.status,
      data: error.response?.data,
    },
  });
};

export const setSentryUser = (user: ProfileAsMember) => {
  Sentry.setUser({ ...user, id: user._id });
};

export const resetSentryUser = () => Sentry.setUser(null);
