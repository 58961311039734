import { useNavigate, Link } from 'react-router-dom';
import { Button, Text } from '@pickme/ui';
import { t } from 'i18next';

import PickMeLogo from 'resources/images/pickme_logo.png';

import './PageNotFound.scss';

function PageNotFound() {
  const navigate = useNavigate();

  return (
    <div className='page-not-found'>
      <Link to={import.meta.env.VITE_ADMIN_URL}>
        <img src={PickMeLogo} alt='pickme logo in 404 page' />
      </Link>
      <Text size='md1'>{t('payment:pages.redirect.pageNotFound.title')}</Text>
      <Button
        size='lg'
        onClick={() => {
          navigate(-1);
        }}
      >
        <Text size='md3'>{t('payment:pages.redirect.pageNotFound.back')}</Text>
      </Button>
    </div>
  );
}

export default PageNotFound;
