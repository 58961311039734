import { Toast as PickmeToast } from '@pickme/ui';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { toast } from 'states/toast';

function Toast() {
  const { isVisible, type, message } = useRecoilValue(toast);
  const setToast = useSetRecoilState(toast);

  return (
    <PickmeToast
      isVisible={isVisible}
      type={type}
      message={message}
      onHide={() => setToast({ isVisible: false, message, type })}
    />
  );
}

export default Toast;
