import { Plan } from '@pickme/core';
import { useQuery } from '@tanstack/react-query';

import { createGetOrganizationRequest } from 'apis/organization';

import { setPlan } from 'functions/gtag';

export const useGetOrganization = (organizationId: string) =>
  useQuery(['organization', organizationId], async () => {
    if (!organizationId) {
      throw new Error();
    }

    const { data } = await createGetOrganizationRequest(organizationId);

    setPlan({
      plan: data.subscription?.type ?? Plan.Free,
      voterLimit: data.subscription?.voterLimit ?? 0,
    });

    return data;
  });
