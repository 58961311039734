import layout from './payment/layout';

import bill from './payment/pages/bill';
import redirect from './payment/pages/redirect';

import modal from './payment/components/modal';
import card from './payment/components/card';

import queryHooks from './payment/query-hooks';

import functions from './payment/functions';

import error from './payment/error';
import term from './payment/term';

export default {
  payment: {
    layout,
    pages: {
      bill,
      redirect,
    },
    components: {
      modal,
      card,
    },
    'query-hooks': queryHooks,
    functions,
    error,
    term,
  },
};
