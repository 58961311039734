import { atom } from 'recoil';

const initialState: {
  isVisible: boolean;
  title: string;
  message: string;
} = {
  isVisible: false,
  title: '',
  message: '',
};

export const messageModal = atom({
  key: 'message-modal',
  default: initialState,
});

export const adminRedirectModal = atom({
  key: 'payment-success-modal',
  default: {
    isVisible: false,
    title: '',
    message: '',
  },
});
