import ReactDOM from 'react-dom/client';

import './setupAxios';
import './setupI18n';

import { initSentry } from './functions/sentry';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<App />);

initSentry();
