import { ReactNode, useEffect } from 'react';

import { useGetSessionAsMember } from 'query-hooks/auth';
import { useGetOrganization } from 'query-hooks/organization';

import { getOrganizationIdFromQueryString, redirectToAdmin } from 'functions/url';

function Auth({ children }: { children: ReactNode }) {
  const organizationId = getOrganizationIdFromQueryString();
  const { data: organizationData, isFetched: isOrganizationDataFetched } =
    useGetOrganization(organizationId);
  const { data: sessionData, isFetched: isSessionDataFetched } =
    useGetSessionAsMember(organizationId);
  const isSigned = !!sessionData?._id;
  const isValidOrganizationId = !!organizationData?._id;

  useEffect(() => {
    if (!isOrganizationDataFetched) {
      return;
    }

    if (!isValidOrganizationId) {
      redirectToAdmin();
    }
  }, [isValidOrganizationId, isOrganizationDataFetched]);

  useEffect(() => {
    if (!isSessionDataFetched) {
      return;
    }

    if (!isSigned) {
      redirectToAdmin();
    }
  }, [isSessionDataFetched, isSigned]);

  // TODO: ReactNode를 반환하기 위함, allowExpressions 옵션 고려
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}

export default Auth;
