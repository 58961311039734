import { MemberLevel, Plan } from '@pickme/core';

export function setUser({ userId, spaceId }: { userId: string; spaceId: string }) {
  if (!window.gtag) {
    return;
  }

  window.gtag('set', {
    user_id: userId,
    user_properties: {
      space: spaceId,
      memberLevel: MemberLevel.Organ,
    },
  });
}

export function setPlan({ plan, voterLimit }: { plan: Plan; voterLimit: number }) {
  if (!window.gtag) {
    return;
  }

  window.gtag('set', {
    user_properties: {
      plan,
      voterLimit,
    },
  });
}

export function sendReplacePlanEvent({ plan }: { plan: Plan }) {
  if (!window.gtag) {
    return;
  }

  window.gtag('event', 'replace-plan', {
    plan,
  });
}

export function sendSubscribeEvent({
  plan,
  voterLimit,
  price,
}: {
  plan: Plan;
  voterLimit: number;
  price: number;
}) {
  if (!window.gtag) {
    return;
  }

  window.gtag('event', 'subscribe', {
    plan,
    voterLimit,
    currency: 'KRW',
    value: price,
    price,
    quantity: 1,
  });
}
