import { useEffect } from 'react';
import { Plan } from '@pickme/core';

import PlanCards from 'components/features/plan/Cards';
import PlanLayout from 'components/common/layout/Plan';

import { useGetOrganization } from 'query-hooks/organization';

import { getOrganizationIdFromQueryString } from 'functions/url';

import { useNavigateWithOrganizationId } from 'hooks/useNavigateWithOrganizationId';

function PlanGuide() {
  const navigate = useNavigateWithOrganizationId();

  const organizationId = getOrganizationIdFromQueryString();
  const { data: organizationData } = useGetOrganization(organizationId);
  const plan = organizationData?.subscription?.type;

  useEffect(() => {
    if (plan && [Plan.Basic, Plan.Standard, Plan.Premium].includes(plan)) {
      navigate(`/plan/replace?type=monthly`, { replace: true });
    }

    if (plan && [Plan.Enterprise, Plan.Campus].includes(plan)) {
      navigate(`/`, { replace: true });
    }
  }, [plan]);

  return (
    <PlanLayout>
      {plan && (
        <PlanCards
          plan={plan}
          onPlanClick={(selectedPlan) => navigate(`/bill?planType=${selectedPlan}&voterLimit=31`)}
        />
      )}
    </PlanLayout>
  );
}

export default PlanGuide;
