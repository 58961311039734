import {
  TYPES_AVAILABLE_FOR_PURCHASE,
  CONTRACT_TYPES_AVAILABLE_FOR_PURCHASE,
  VOTER_LIMIT,
} from 'constants/payment';

import { SubscriptionOrderForm } from 'types/payment';

export const validateSubscriptionOrderForm = (orderForm: Partial<SubscriptionOrderForm>) => {
  if (!orderForm.planType || !orderForm.contractType || !orderForm.voterLimit) {
    return false;
  }

  if (!TYPES_AVAILABLE_FOR_PURCHASE.includes(orderForm.planType)) {
    return false;
  }

  if (!CONTRACT_TYPES_AVAILABLE_FOR_PURCHASE.includes(orderForm.contractType)) {
    return false;
  }

  if (orderForm.voterLimit < VOTER_LIMIT.MIN || orderForm.voterLimit > VOTER_LIMIT.MAX) {
    return false;
  }

  return true;
};
