export default {
  parseSubscriptionSuccessMessage: {
    plan: '플랜',
    contractType: {
      monthly: '월간 구독',
      yearly: '연간 구독',
    },
    message:
      '{{planText}}의 {{contractTypeText}}이 시작되었습니다. 등록된 결제 정보는 다음 정기 결제일에 반영됩니다.',
  },
};
