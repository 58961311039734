import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Modal, ModalActions, ModalBody, ModalHeader, Text } from '@pickme/ui';
import { t } from 'i18next';

import { messageModal } from 'states/modal';

function MessageModal() {
  const { isVisible, title, message } = useRecoilValue(messageModal);
  const setMessageModal = useSetRecoilState(messageModal);

  const onClose = () => {
    setMessageModal({ isVisible: false, title, message });

    setTimeout(() => {
      setMessageModal({ isVisible: false, title: '', message: '' });
    }, 500);
  };

  return (
    <Modal isVisible={isVisible} onClose={onClose}>
      <ModalHeader title={title} onClose={onClose} />
      <ModalBody>
        <Text size='sm1' fontWeight={300} color='gray-400'>
          {message}
        </Text>
      </ModalBody>
      <ModalActions
        buttons={[
          {
            text: t('payment:components.modal.message.button.submit'),
            onClick: onClose,
          },
        ]}
      />
    </Modal>
  );
}

export default MessageModal;
