import './index.scss';

function Spinner() {
  return (
    <div className='loading'>
      <div className='spinner' />
    </div>
  );
}

export default Spinner;
