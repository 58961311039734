import { Apis } from 'types/api';
import { ProfileAsMember } from 'types/auth';
import { createHttpRequest } from './common';

const APIS: Apis = {
  getSession: { method: 'GET', url: '/api/auth/profile' },
};

export const createGetSessionAsMemberRequest = (organizationId: string) =>
  createHttpRequest<ProfileAsMember>({
    ...APIS.getSession,
    headers: { 'Cache-Control': 'no-cache', 'organization-oid': organizationId },
  });
