import { PaymentCard } from '@pickme/ui';
import { Plan } from '@pickme/core';
import { t } from 'i18next';

import './index.scss';

function Cards({ plan, onPlanClick }: { plan: Plan; onPlanClick: (plan: Plan) => void }) {
  const using = t('payment:components.card.using');
  const select = t('payment:components.card.select');

  return (
    <div className='plan-cards'>
      <PaymentCard
        disabled={plan === Plan.Free}
        buttonText={plan === Plan.Free ? using : select}
        type={Plan.Free}
      />
      <PaymentCard
        disabled={plan === Plan.Basic}
        buttonText={plan === Plan.Basic ? using : select}
        type={Plan.Basic}
        onClick={() => onPlanClick(Plan.Basic)}
      />
      <PaymentCard
        disabled={plan === Plan.Standard}
        buttonText={plan === Plan.Standard ? using : select}
        type={Plan.Standard}
        onClick={() => onPlanClick(Plan.Standard)}
      />
      <PaymentCard
        disabled={plan === Plan.Premium}
        buttonText={plan === Plan.Premium ? using : select}
        type={Plan.Premium}
        onClick={() => onPlanClick(Plan.Premium)}
      />
    </div>
  );
}

export default Cards;
