import { ReactNode } from 'react';
import { PaymentFAQ, Text } from '@pickme/ui';
import { t } from 'i18next';

import './index.scss';

function PlanLayout({ children }: { children?: ReactNode }) {
  return (
    <div className='plan-layout'>
      <div className='plan-layout__title'>
        <Text type='h2' fontWeight={800}>
          {t('payment:layout.plan.title')}
        </Text>
      </div>

      {children}

      <div className='plan-layout__description'>
        <Text type='b3' fontWeight={400} color='gray-400'>
          * {t('payment:layout.plan.vat')}
        </Text>
      </div>

      <div className='plan-layout__faq'>
        <PaymentFAQ />
      </div>
    </div>
  );
}

export default PlanLayout;
