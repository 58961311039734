import { Plan } from '@pickme/core';

export const TYPES_AVAILABLE_FOR_PURCHASE = [Plan.Basic, Plan.Standard, Plan.Premium] as const;

export const CONTRACT_TYPES_AVAILABLE_FOR_PURCHASE = ['monthly'];

export const VOTER_LIMIT = {
  MAX: 5000,
  MIN: 31,
};
