import { useEffect, useState } from 'react';

import { useQueryString } from 'hooks/useQueryString';

export const usePaymentCallbackQueryString = () => {
  const [isQueryStringExist, setIsQueryStringExist] = useState(false);

  const { product, voterLimit, planType, contractType, authKey, organizationId } = useQueryString({
    product: '',
    voterLimit: 1,
    planType: '',
    contractType: '',
    authKey: '',
    organizationId: '',
  });

  useEffect(() => {
    if (isQueryStringExist) {
      return;
    }

    if (product && voterLimit && planType && contractType && authKey && organizationId) {
      setIsQueryStringExist(true);
    }
  }, [product, voterLimit, planType, contractType, authKey, organizationId]);

  return {
    isQueryStringExist,
    queryString: {
      product,
      voterLimit,
      planType,
      contractType,
      authKey,
      organizationId,
    },
  };
};
