import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { t } from 'i18next';

import Spinner from 'components/common/Spinner';

import { useQueryString } from 'hooks/useQueryString';

import { messageModal } from 'states/modal';

import { redirectToAdmin } from 'functions/url';

function PaymentFailureCallback() {
  const { message } = useQueryString({ message: '' });

  const setMessageModal = useSetRecoilState(messageModal);

  useEffect(() => {
    setTimeout(() => {
      setMessageModal({
        isVisible: true,
        title: t('payment:error.paymentFailure.title'),
        message: t('payment:error.paymentFailure.message', {
          text: message || t('payment:error.wrongRequest'),
        }),
      });

      redirectToAdmin();
    }, 1000);
  }, [message]);

  return (
    <div className='payment-waiting'>
      <Spinner />
    </div>
  );
}

export default PaymentFailureCallback;
