import { Apis } from 'types/api';
import { OrganizationDetail } from 'types/organization';
import { createHttpRequest } from './common';

const APIS: Apis = {
  getOrganization: { method: 'GET', url: '/api/organization/:organizationId' },
};

export const createGetOrganizationRequest = (organizationId: string) =>
  createHttpRequest<OrganizationDetail>({
    ...APIS.getOrganization,
    params: { organizationId },
    query: { type: 'objectId' },
    headers: {
      'organization-oid': organizationId,
    },
  });
