import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { Text } from '@pickme/ui';
import { Plan } from '@pickme/core';
import { t } from 'i18next';

import BillForm from 'components/features/bill/BillForm';

import { useGetSessionAsMember } from 'query-hooks/auth';
import { useGetOrganization } from 'query-hooks/organization';

import { useQueryString } from 'hooks/useQueryString';
import { useNavigateWithOrganizationId } from 'hooks/useNavigateWithOrganizationId';

import { toast } from 'states/toast';

import { getOrganizationIdFromQueryString } from 'functions/url';

import { TYPES_AVAILABLE_FOR_PURCHASE } from 'constants/payment';

import { PlanForPurchase } from 'types/payment';

import './Bill.scss';

function Bill() {
  const navigate = useNavigateWithOrganizationId();

  const setToast = useSetRecoilState(toast);

  const { planType, voterLimit } = useQueryString<{
    planType: PlanForPurchase | '';
    voterLimit: number;
  }>({
    planType: '',
    voterLimit: 0,
  });

  const organizationId = getOrganizationIdFromQueryString();
  const { data: memberSessionData } = useGetSessionAsMember(organizationId);
  const { data: organizationData } = useGetOrganization(organizationId);

  useEffect(() => {
    if ((planType && !TYPES_AVAILABLE_FOR_PURCHASE.includes(planType)) || voterLimit < 0) {
      navigate(`/guide?type=monthly`, { replace: true });

      setToast({
        isVisible: true,
        type: 'error',
        message: t('payment:error.wrongApproach'),
      });
    }
  }, [planType, voterLimit]);

  return (
    <div className='payment-bill'>
      <div className='payment-bill__title'>
        <Text type='h3' fontWeight={600}>
          {t('payment:pages.bill.title')}
        </Text>
      </div>

      <BillForm
        organizationId={organizationId}
        organizationName={organizationData?.name || ''}
        customerKey={memberSessionData?.id || ''}
        planType={planType || Plan.Basic}
        voterLimit={voterLimit}
      />

      <div className='payment-bill__description'>
        <Text type='b3' fontWeight={400} color='gray-400'>
          {t('payment:pages.bill.cautions.title')}
        </Text>
        <ul>
          <li>
            <Text type='lab1' fontWeight={300} color='gray-400'>
              {t('payment:pages.bill.cautions.voterLimit')}
            </Text>
          </li>
          <li>
            <Text type='lab1' fontWeight={300} color='gray-400'>
              {t('payment:pages.bill.cautions.retry')}
            </Text>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Bill;
