export default {
  replacePlan: {
    success: {
      modal: {
        title: '플랜 변경이 완료되었습니다.',
        message: {
          abort: '구독 중단이 예약되었습니다.',
          replace: '구독 변경이 예약되었습니다.',
        },
      },
    },
  },
  subscription: {
    success: {
      modal: {
        title: '결제가 완료되었습니다.',
      },
    },
  },
};
