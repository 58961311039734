import { useEffect } from 'react';
import { Modal, ModalActions, ModalBody, ModalHeader, Text } from '@pickme/ui';
import { Plan, PLANS } from '@pickme/core';
import { t } from 'i18next';

import Spinner from 'components/common/Spinner';

import { useReplacePlan } from 'query-hooks/payment';

import './index.scss';

type Props = {
  isVisible: boolean;
  from?: Plan;
  to: Plan;
  organizationId: string;
  onClose: () => void;
};

// 프리, 베이직 => 스탠다드

// 프리, 베이직 => 프리미엄
// 스탠다드 => 프리미엄

// 프리미엄 => 스탠다드

const makeDifferenceDescription = (from: Plan, to: Plan) => {
  if (to === Plan.Premium) {
    return (
      <div className='plan-replace-modal__summary'>
        <Text type='b3' fontWeight={400}>
          {t('payment:components.modal.plan-replace.message.desc.prefix', {
            plan: PLANS[Plan.Premium].name.kor,
          })}{' '}
        </Text>
        <Text type='b3' fontWeight={600} color='primary-500'>
          {t('payment:components.modal.plan-replace.message.desc.vote', { number: 20 })}
        </Text>
        <Text type='b3' fontWeight={400}>
          {t('payment:components.modal.plan-replace.message.desc.increase')}
        </Text>
      </div>
    );
  }

  if ((from === Plan.Free || from === Plan.Basic) && to === Plan.Standard) {
    return (
      <div className='plan-replace-modal__summary'>
        <Text type='b3' fontWeight={400}>
          {t('payment:components.modal.plan-replace.message.desc.prefix', {
            plan: PLANS[Plan.Standard].name.kor,
          })}{' '}
        </Text>
        <Text type='b3' fontWeight={600} color='primary-500'>
          {t('payment:components.modal.plan-replace.message.desc.vote', { number: 5 })}
        </Text>
        <Text type='b3' fontWeight={400}>
          {t('payment:components.modal.plan-replace.message.desc.increase')}
        </Text>
      </div>
    );
  }

  if ((from === Plan.Premium || from === Plan.Standard) && to === Plan.Basic) {
    return (
      <div className='plan-replace-modal__summary'>
        <Text type='b3' fontWeight={400}>
          {t('payment:components.modal.plan-replace.message.desc.prefix', {
            plan: PLANS[Plan.Basic].name.kor,
          })}{' '}
        </Text>
        <Text type='b3' fontWeight={600} color='primary-500'>
          {t('payment:components.modal.plan-replace.message.desc.vote', { number: 1 })}
        </Text>
        <Text type='b3' fontWeight={400}>
          {t('payment:components.modal.plan-replace.message.desc.decrease')}
        </Text>
      </div>
    );
  }

  if (from === 'premium' && to === 'standard') {
    return (
      <div className='plan-replace-modal__summary'>
        <Text type='b3' fontWeight={400}>
          {t('payment:components.modal.plan-replace.message.desc.prefix', {
            plan: PLANS[Plan.Standard].name.kor,
          })}
        </Text>
        <Text type='b3' fontWeight={600} color='primary-500'>
          {t('payment:components.modal.plan-replace.message.desc.vote', { number: 5 })}
        </Text>
        <Text type='b3' fontWeight={400}>
          {t('payment:components.modal.plan-replace.message.desc.decrease')}
        </Text>
      </div>
    );
  }

  return <div />;
};

function PlanReplaceModal({ isVisible, onClose, from, to, organizationId }: Props) {
  const { isLoading, mutate, isSuccess } = useReplacePlan();

  const onSubmit = () => {
    if (isLoading) {
      return;
    }
    mutate({ plan: to, organizationId });
  };

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess]);

  return (
    <Modal className='plan-replace-modal' size='lg' isVisible={isVisible} onClose={onClose}>
      <ModalHeader title={t('payment:components.modal.plan-replace.title')} onClose={onClose} />
      <ModalBody>
        <Text type='b3' fontWeight={400}>
          {t('payment:components.modal.plan-replace.message.planTo', {
            plan: PLANS[to].name.kor,
          })}
        </Text>
        {from && makeDifferenceDescription(from, to)}
      </ModalBody>
      <ModalActions
        buttons={[
          {
            text: t('payment:components.modal.plan-replace.buttons.cancel'),
            variant: 'gray',
            onClick: onClose,
          },
          {
            text: isLoading ? (
              <Spinner />
            ) : (
              t('payment:components.modal.plan-replace.buttons.submit')
            ),
            disabled: isLoading,
            onClick: onSubmit,
          },
        ]}
      />
    </Modal>
  );
}

export default PlanReplaceModal;
