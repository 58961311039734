import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { t } from 'i18next';

import Spinner from 'components/common/Spinner';

import { useCreatePurchaseSubscription } from 'query-hooks/payment';

import { usePaymentCallbackQueryString } from 'hooks/usePaymentCallbackQueryString';

import { toast } from 'states/toast';

import { validateSubscriptionOrderForm } from 'functions/validator';
import { redirectToAdmin } from 'functions/url';

import { SubscriptionOrderForm, PlanForPurchase } from 'types/payment';

import './PaymentSuccessCallback.scss';

function PaymentSuccessCallback() {
  const setToast = useSetRecoilState(toast);

  const { isQueryStringExist, queryString } = usePaymentCallbackQueryString();

  const { mutate, isLoading } = useCreatePurchaseSubscription();

  const payForSubscription = (
    orderForm: SubscriptionOrderForm,
    authKey: string,
    organizationId: string,
  ) => {
    if (!isLoading) {
      mutate({ orderForm, authKey, organizationId });
    }
  };

  const handleSubscriptionCallback = () => {
    const { authKey, organizationId } = queryString;
    const orderForm: SubscriptionOrderForm = {
      planType: queryString.planType as PlanForPurchase,
      contractType: queryString.contractType as 'monthly' | 'yearly',
      voterLimit: queryString.voterLimit,
    };

    if (!authKey || !validateSubscriptionOrderForm(orderForm)) {
      setToast({
        isVisible: true,
        type: 'error',
        message: t('payment:error.wrongApproach'),
      });

      redirectToAdmin();

      return;
    }

    payForSubscription(orderForm, authKey, organizationId);
  };

  useEffect(() => {
    if (!isQueryStringExist) {
      return;
    }

    if (queryString.product === 'subscription') {
      handleSubscriptionCallback();
      return;
    }

    setToast({
      isVisible: true,
      type: 'error',
      message: t('payment:error.paymentFailure.title'),
    });

    redirectToAdmin();
  }, [isQueryStringExist]);

  return (
    <div className='payment-waiting'>
      <Spinner />
    </div>
  );
}

export default PaymentSuccessCallback;
